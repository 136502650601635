/**
 * replaceTextInDocument
 *
 * This function traverses the entire document and replaces text content
 * based on specified rules. It can perform simple text replacements or
 * wrap text in HTML tags.
 *
 * @param rules An array of ReplacementRule objects defining the text to search for and how to replace it.
 *
 * Usage:
 *
 * replaceTextInDocument([
 *   { search: /text to find/gi, replace: 'replacement text' },
 *   { search: /wrap this/gi, replace: '<span class="highlight">%s</span>' },
 *   { search: /dynamic replace/gi, replace: (match) => `processed: ${match.toUpperCase()}` }
 * ]);
 *
 * ReplacementRule object:
 * - search: string | RegExp - The text to search for. Can be a string or a regular expression.
 * - replace: string | function - The replacement. Can be a string, a string with '%s' placeholder, or a function.
 *
 * String replacement:
 * - Simple string: Directly replaces the matched text.
 * - String with '%s': Replaces '%s' with the matched text, allowing for HTML wrapping.
 *
 * Function replacement:
 * - Receives the matched text as an argument and should return the replacement string.
 *
 * Note:
 * - The function modifies the DOM directly.
 * - It's recommended to run this function after the DOM is fully loaded.
 * - Complex or numerous replacements may impact performance on large documents.
 */

interface ReplacementRule {
  search: string | RegExp
  replace: string | ((match: string) => string)
}

function replaceTextInDocument(rules: ReplacementRule[]): void {
  const walker: TreeWalker = document.createTreeWalker(
    document.body,
    NodeFilter.SHOW_TEXT,
    null,
  )

  const nodesToReplace: { node: Text; replacements: ReplacementRule[] }[] = []
  let node: Node | null

  while ((node = walker.nextNode()) !== null) {
    if (node.nodeType === Node.TEXT_NODE && node.nodeValue) {
      const textNode = node as Text
      const applicableRules = rules.filter((rule) =>
        textNode.nodeValue!.match(rule.search),
      )
      if (applicableRules.length > 0) {
        nodesToReplace.push({ node: textNode, replacements: applicableRules })
      }
    }
  }

  nodesToReplace.forEach(({ node, replacements }) => {
    let newContent = node.nodeValue || ''

    replacements.forEach((rule) => {
      newContent = newContent.replace(rule.search, (match) => {
        if (typeof rule.replace === 'function') {
          return rule.replace(match)
        } else if (rule.replace.includes('%s')) {
          return rule.replace.replace('%s', match)
        } else {
          return rule.replace
        }
      })
    })

    if (newContent.includes('<') && newContent.includes('>')) {
      const span = document.createElement('span')
      span.innerHTML = newContent
      node.parentNode!.replaceChild(span, node)
    } else {
      node.nodeValue = newContent
    }
  })
}

export default replaceTextInDocument

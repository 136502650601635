import type { AlpineComponent } from 'alpinejs'
import Swiper from 'swiper'
import { Pagination, Navigation, A11y, Mousewheel } from 'swiper/modules'
import 'swiper/css'

export function SingleSlider(): AlpineComponent<{
  swiper: Swiper | undefined
  init(): void
  discard(): void
}> {
  return {
    swiper: undefined,

    init() {
      this.swiper = new Swiper(this.$refs.slider, {
        modules: [Pagination, Navigation, A11y, Mousewheel],
        slidesPerView: 1,
        grabCursor: true,
        // breakpoints: {
        //   768: {
        //     pagination: {
        //       type: 'fraction',
        //       clickable: false,
        //     },
        //   },
        // },
        navigation: {
          nextEl: this.$refs.next,
          prevEl: this.$refs.prev,
          disabledClass: 'opacity-50 cursor-not-allowed',
        },
        pagination: {
          el: this.$refs.pagination,
          type: 'fraction',
        },
        mousewheel: {
          forceToAxis: true,
        },
      })
    },

    discard() {
      this.swiper && this.swiper.destroy()
    },
  }
}

/**
 * Modified from the original to accept custom poster
 * Original: https://github.com/justinribeiro/lite-youtube
 *
 * The shadowDom / Intersection Observer version of Paul's concept:
 * https://github.com/paulirish/lite-youtube-embed
 *
 * A lightweight YouTube embed. Still should feel the same to the user, just
 * MUCH faster to initialize and paint.
 *
 * Thx to these as the inspiration
 *   https://storage.googleapis.com/amp-vs-non-amp/youtube-lazy.html
 *   https://autoplay-youtube-player.glitch.me/
 *
 * Once built it, I also found these (👍👍):
 *   https://github.com/ampproject/amphtml/blob/master/extensions/amp-youtube
 *   https://github.com/Daugilas/lazyYT https://github.com/vb/lazyframe
 */
export class LiteYTEmbed extends HTMLElement {
  shadowRoot!: ShadowRoot
  private domRefFrame!: HTMLDivElement
  private domRefImg!: {
    fallback: HTMLImageElement
    webp: HTMLSourceElement
    jpeg: HTMLSourceElement
  }
  private domRefPlayButton!: HTMLButtonElement
  private static isPreconnected = false
  private isIframeLoaded = false

  constructor() {
    super()
    this.setupDom()
  }

  static get observedAttributes(): string[] {
    return ['videoid', 'playlistid']
  }

  connectedCallback(): void {
    this.addEventListener('pointerover', LiteYTEmbed.warmConnections, {
      once: true,
    })

    this.addEventListener('click', () => this.addIframe())
  }

  get videoId(): string {
    return encodeURIComponent(this.getAttribute('videoid') || '')
  }

  set videoId(id: string) {
    this.setAttribute('videoid', id)
  }

  get playlistId(): string {
    return encodeURIComponent(this.getAttribute('playlistid') || '')
  }

  set playlistId(id: string) {
    this.setAttribute('playlistid', id)
  }

  get videoTitle(): string {
    return this.getAttribute('videotitle') || 'Video'
  }

  set videoTitle(title: string) {
    this.setAttribute('videotitle', title)
  }

  get videoPlay(): string {
    return this.getAttribute('videoPlay') || 'Play'
  }

  set videoPlay(name: string) {
    this.setAttribute('videoPlay', name)
  }

  get videoStartAt(): string {
    return this.getAttribute('videoStartAt') || '0'
  }

  get autoLoad(): boolean {
    return this.hasAttribute('autoload')
  }

  get noCookie(): boolean {
    return this.hasAttribute('nocookie')
  }

  get posterQuality(): string {
    return this.getAttribute('posterquality') || 'hqdefault'
  }

  get posterLoading(): HTMLImageElement['loading'] {
    return (
      (this.getAttribute('posterloading') as HTMLImageElement['loading']) ||
      'lazy'
    )
  }

  get posterImage(): string {
    return this.getAttribute('posterimage') || ''
  }

  get params(): string {
    return `start=${this.videoStartAt}&${this.getAttribute('params')}`
  }

  set params(opts: string) {
    this.setAttribute('params', opts)
  }

  /**
   * Define our shadowDOM for the component
   */
  private setupDom(): void {
    const shadowDom = this.attachShadow({ mode: 'open' })
    let nonce = ''
    if (window.liteYouTubeNonce) {
      nonce = `nonce="${window.liteYouTubeNonce}"`
    }
    shadowDom.innerHTML = `
      <style ${nonce}>
        :host {
          contain: content;
          display: block;
          position: relative;
          width: 100%;
          padding-bottom: calc(100% / (16 / 9));
          --lyt-animation: all 0.2s cubic-bezier(0, 0, 0.2, 1);
          --lyt-play-btn-default: #212121;
          --lyt-play-btn-hover: #f00;
        }

        @media (max-width: 40em) {
          :host([short]) {
            padding-bottom: calc(100% / (9 / 16));
          }
        }

        #frame, #fallbackPlaceholder, iframe {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
        }

        #frame {
          cursor: pointer;
        }

        #fallbackPlaceholder {
          object-fit: cover;
        }

        #playButton {
          width: 130px;
          height: 130px;
          background-color: transparent;
          z-index: 1;
          opacity: 1;
          transition: var(--lyt-animation);
          border: 0;
        }

        #frame:hover > #playButton {
          transform: translate3d(-50%, -50%, 0) scale(1.3);
        }

        #playButton,
        #playButton:before {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
          cursor: inherit;
        }

        /* Post-click styles */
        .activated {
          cursor: unset;
        }

        #frame.activated::before,
        #frame.activated > #playButton ,
        #frame.activated > img {
          display: none;
        }
      </style>
      <div id="frame">
        <slot name="poster">
          <picture>
            <source id="webpPlaceholder" type="image/webp">
            <source id="jpegPlaceholder" type="image/jpeg">
            <img id="fallbackPlaceholder" referrerpolicy="origin" loading="lazy">
          </picture>
        </slot>
        <button id="playButton">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 216 216">
            <g>
              <circle cx="108" cy="108" r="100" fill="#4D4253"/>
            </g>
            <path fill="#FDF9E4" d="M123.5 106.2a2 2 0 0 1 0 3.6l-22.9 11.5a2 2 0 0 1-2.8-1.8v-23a2 2 0 0 1 2.8-1.8l22.9 11.5Z"/>
          </svg>
        </button>
      </div>
    `
    this.domRefFrame = shadowDom.querySelector<HTMLDivElement>('#frame')!
    this.domRefImg = {
      fallback: shadowDom.querySelector('#fallbackPlaceholder')!,
      webp: shadowDom.querySelector('#webpPlaceholder')!,
      jpeg: shadowDom.querySelector('#jpegPlaceholder')!,
    }
    this.domRefPlayButton = shadowDom.querySelector('#playButton')!
  }

  /**
   * Parse our attributes and fire up some placeholders
   */
  private setupComponent(): void {
    this.initImagePlaceholder()

    this.domRefPlayButton.setAttribute(
      'aria-label',
      `${this.videoPlay}: ${this.videoTitle}`,
    )
    this.setAttribute('title', `${this.videoPlay}: ${this.videoTitle}`)

    if (this.autoLoad || this.isYouTubeShort()) {
      this.initIntersectionObserver()
    }
  }

  /**
   * Lifecycle method that we use to listen for attribute changes to period
   * @param {*} name
   * @param {*} oldVal
   * @param {*} newVal
   */
  attributeChangedCallback(
    name: string,
    oldVal: unknown,
    newVal: unknown,
  ): void {
    switch (name) {
      case 'videoid':
      case 'playlistid':
      case 'videoTitle':
      case 'videoPlay': {
        if (oldVal !== newVal) {
          this.setupComponent()

          // if we have a previous iframe, remove it and the activated class
          if (this.domRefFrame.classList.contains('activated')) {
            this.domRefFrame.classList.remove('activated')
            this.shadowRoot.querySelector('iframe')!.remove()
            this.isIframeLoaded = false
          }
        }
        break
      }
      default:
        break
    }
  }

  /**
   * Inject the iframe into the component body
   * @param {boolean} isIntersectionObserver
   */
  private addIframe(isIntersectionObserver = false): void {
    if (!this.isIframeLoaded) {
      // Don't autoplay the intersection observer injection, it's weird
      let autoplay = isIntersectionObserver ? 0 : 1
      const wantsNoCookie = this.noCookie ? '-nocookie' : ''
      let embedTarget
      if (this.playlistId) {
        embedTarget = `?listType=playlist&list=${this.playlistId}&`
      } else {
        embedTarget = `${this.videoId}?`
      }

      // Oh wait, you're a YouTube short, so let's try to make you more workable
      if (this.isYouTubeShort()) {
        this.params = `loop=1&mute=1&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&playlist=${this.videoId}`
        autoplay = 1
      }

      const iframeHTML = `
<iframe frameborder="0"
  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
  src="https://www.youtube${wantsNoCookie}.com/embed/${embedTarget}autoplay=${autoplay}&${this.params}"
></iframe>`
      this.domRefFrame.insertAdjacentHTML('beforeend', iframeHTML)
      this.domRefFrame.classList.add('activated')
      this.isIframeLoaded = true
      this.attemptShortAutoPlay()
      this.dispatchEvent(
        new CustomEvent('liteYoutubeIframeLoaded', {
          detail: {
            videoId: this.videoId,
          },
          bubbles: true,
          cancelable: true,
        }),
      )
    }
  }

  /**
   * Setup the placeholder image for the component
   */
  private initImagePlaceholder(): void {
    const posterUrlWebp = `https://i.ytimg.com/vi_webp/${this.videoId}/${this.posterQuality}.webp`
    const posterUrlJpeg = `https://i.ytimg.com/vi/${this.videoId}/${this.posterQuality}.jpg`
    this.domRefImg.fallback.loading = this.posterLoading
    this.domRefImg.webp.srcset = posterUrlWebp
    this.domRefImg.jpeg.srcset = posterUrlJpeg
    this.domRefImg.fallback.src = posterUrlJpeg
    this.domRefImg.fallback.setAttribute(
      'aria-label',
      `${this.videoPlay}: ${this.videoTitle}`,
    )
    this.domRefImg?.fallback?.setAttribute(
      'alt',
      `${this.videoPlay}: ${this.videoTitle}`,
    )
  }

  /**
   * Setup the Intersection Observer to load the iframe when scrolled into view
   */
  private initIntersectionObserver(): void {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    }

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !this.isIframeLoaded) {
          LiteYTEmbed.warmConnections()
          this.addIframe(true)
          observer.unobserve(this)
        }
      })
    }, options)

    observer.observe(this)
  }

  /**
   * This is a terrible hack to attempt to get YouTube Short-like autoplay on
   * mobile viewports. It's this way because:
   * 1. YouTube's Iframe embed does not offer determinism when loading
   * 2. Attempting to use onYouTubeIframeAPIReady() does not work in 99% of
   *    cases
   * 3. You can _technically_ load the Frame API library and do more advanced
   *    things, but I don't want to burn the thread of the wire with its
   *    shenanigans since this an edge case.
   * @private
   */
  private attemptShortAutoPlay() {
    if (this.isYouTubeShort()) {
      setTimeout(() => {
        this.shadowRoot
          .querySelector('iframe')
          ?.contentWindow?.postMessage(
            '{"event":"command","func":"' + 'playVideo' + '","args":""}',
            '*',
          )
        // for youtube video recording demo
      }, 2000)
    }
  }

  /**
   * A hacky attr check and viewport peek to see if we're going to try to enable
   * a more friendly YouTube Short style loading
   * @returns boolean
   */
  private isYouTubeShort(): boolean {
    return (
      this.getAttribute('short') === '' &&
      window.matchMedia('(max-width: 40em)').matches
    )
  }

  /**
   * Add a <link rel={preload | preconnect} ...> to the head
   * @param {string} kind
   * @param {string} url
   * @param {string} as
   */
  private static addPrefetch(kind: string, url: string): void {
    const linkElem = document.createElement('link')
    linkElem.rel = kind
    linkElem.href = url
    linkElem.crossOrigin = 'true'
    document.head.append(linkElem)
  }

  /**
   * Begin preconnecting to warm up the iframe load Since the embed's network
   * requests load within its iframe, preload/prefetch'ing them outside the
   * iframe will only cause double-downloads. So, the best we can do is warm up
   * a few connections to origins that are in the critical path.
   *
   * Maybe `<link rel=preload as=document>` would work, but it's unsupported:
   * http://crbug.com/593267 But TBH, I don't think it'll happen soon with Site
   * Isolation and split caches adding serious complexity.
   */
  private static warmConnections(): void {
    if (LiteYTEmbed.isPreconnected || window.liteYouTubeIsPreconnected) return
    // we don't know which image type to preload, so warm the connection
    LiteYTEmbed.addPrefetch('preconnect', 'https://i.ytimg.com/')

    // Host that YT uses to serve JS needed by player, per amp-youtube
    LiteYTEmbed.addPrefetch('preconnect', 'https://s.ytimg.com')

    // The iframe document and most of its subresources come right off
    // youtube.com
    LiteYTEmbed.addPrefetch('preconnect', 'https://www.youtube.com')

    // The botguard script is fetched off from google.com
    LiteYTEmbed.addPrefetch('preconnect', 'https://www.google.com')

    // TODO: Not certain if these ad related domains are in the critical path.
    // Could verify with domain-specific throttling.
    LiteYTEmbed.addPrefetch('preconnect', 'https://googleads.g.doubleclick.net')
    LiteYTEmbed.addPrefetch('preconnect', 'https://static.doubleclick.net')
    LiteYTEmbed.isPreconnected = true

    // multiple embeds in the same page don't check for each other
    window.liteYouTubeIsPreconnected = true
  }
}
// Register custom element
customElements.define('lite-youtube', LiteYTEmbed)

declare global {
  interface HTMLElementTagNameMap {
    'lite-youtube': LiteYTEmbed
  }
  interface Window {
    liteYouTubeNonce: string
    liteYouTubeIsPreconnected: boolean
  }
}

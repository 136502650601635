function widthWithOutPadding(element: HTMLElement) {
  const style = window.getComputedStyle(element)

  const padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight)
  return element.offsetWidth - padding
}

function fitText(elements?: NodeListOf<HTMLElement>) {
  elements = elements || document.querySelectorAll<HTMLElement>('.fit-text')

  elements.forEach((element) => {
    const container = element.parentElement
    if (!container) return

    const containerWidth = widthWithOutPadding(container)
    const textWidth = element.scrollWidth
    const style = window.getComputedStyle(element)
    const fontSize = parseFloat(style.fontSize)

    if (textWidth > containerWidth) {
      element.style.fontSize = fontSize - 10 + 'px'

      fitText(elements)
    }
  })
}

function debounceFitText(wait: number) {
  let timeout: number
  const func = fitText()

  return function executedFunction(func: () => void) {
    const later = () => {
      clearTimeout(timeout)
      func()
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export { fitText, debounceFitText }
